import { FunctionComponent } from "react";

import Image from "next/image";

type Props = {
  message?: string;
};

export const LoadingView: FunctionComponent<Props> = ({ message }: Props) => {
  return (
    <>
      <div className="flex h-screen flex-col items-center justify-center gap-5 bg-white">
        <div className="flex animate-bounce gap-10">
          <Image src="/logo-cairs.png" height={100} width={100} alt="logo" />
          <Image src="/logo.png" height={100} width={100} alt="logo" />
        </div>
        {message && <p className="text-gray-500">{message}</p>}
        <p className="text-gray-500">Saponにリニューアルしました</p>
      </div>
    </>
  );
};
