// クエリキャッシュを効かせる機能を有効にするかどうか
const enableKey = "enable-tanstack-query-persistence";
export const isQueryPersistenceEnabled = () =>
  window.localStorage.getItem(enableKey) === "yes";

export const toggleQueryPersistence = () => {
  if (isQueryPersistenceEnabled()) {
    window.localStorage.removeItem(enableKey);
    window.localStorage.removeItem("REACT_QUERY_OFFLINE_CACHE");
  } else {
    window.localStorage.setItem(enableKey, "yes");
  }
};
