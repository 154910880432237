import { FunctionComponent } from "react";

import Image from "next/image";

export const DomainNotice: FunctionComponent = () => {
  return (
    <>
      <div className="mt-48 flex w-full flex-col items-center gap-3">
        <Image src="/logo.png" height={100} width={100} alt="logo" />
        <div className="mt-5 font-bold">URL移転のお知らせ</div>

        <div className="flex flex-col items-center">
          <div>chat.seamth.com</div>
          <div>は移転しました。</div>
        </div>

        <div className="flex flex-col items-center">
          <a href="https://www.cairs.co.jp" className="btn btn-primary btn-sm">
            www.cairs.co.jp
          </a>
          <div>をご利用ください。</div>
        </div>
      </div>
    </>
  );
};
