import "@/styles/globals.css";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { setUser as setSentryUser } from "@sentry/nextjs";
import { AnimatePresence } from "framer-motion";
import Head from "next/head";
import { GoogleAnalytics } from "nextjs-google-analytics";
import OneSignal from "react-onesignal";

import type { AppType } from "next/app";

import { DomainNotice } from "@/components/domainNotice";
import { UserProvider, useUser } from "@/components/firebase";
import { useIsNative } from "@/hooks/useIsNative";
import { isDevelopment } from "@/server/env";
import { trpc } from "@/utils/trpc";

const oldDomain = "chat.seamth.com";
const oneSignalAppId = process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID!;

type Props = {
  children: React.ReactNode;
};

// OneSignalの初期化を行う
const initializeOneSignal = async () => {
  await OneSignal.init({
    appId: oneSignalAppId,
    notifyButton: {
      enable: true,
    },
    serviceWorkerParam: {
      scope: "/push/onesignal/",
    },
    serviceWorkerPath: "push/onesignal/OneSignalSDKWorker.js",
    serviceWorkerUpdaterPath: "push/onesignal/OneSignalSDKUpdaterWorker.js",
    allowLocalhostAsSecureOrigin: isDevelopment,
    autoResubscribe: true,
    autoRegister: true,
  });
};

// OneSignalのログインを行う
// external_idを指定することでユーザーを紐付ける
const useLoginOneSignal = () => {
  const getUserNotificationToken =
    trpc.user.userToken.useMutation().mutateAsync;

  return useCallback(async () => {
    const token = await getUserNotificationToken();
    if (token) {
      await OneSignal.login(token);
    }
  }, []);
};

// サーバーのPush通知のOn/Offを取得する
const useUpdatePushEnabled = () => {
  const { isLogin } = useUser();
  const { data, isLoading, refetch } = trpc.user.userData.useQuery(undefined, {
    enabled: isLogin,
  });
  const { mutate } = trpc.user.updateUserSettings.useMutation();

  return useCallback(
    async (enabled: boolean) => {
      if (isLoading) return;
      if (!data) return;

      mutate(
        {
          ...data,
          pushEnabled: enabled,
        },
        {
          onSuccess: refetch,
        },
      );
    },
    [isLoading, data],
  );
};

// 権限が変更されたときにOneSignalにログインする
const useLoginOneSignalOnChangePermission = () => {
  const login = useLoginOneSignal();
  const updatePushEnabled = useUpdatePushEnabled();
  useEffect(() => {
    OneSignal.Notifications.addEventListener(
      "permissionChange",
      async (permission) => {
        updatePushEnabled(permission);

        if (permission) {
          login();
        }
      },
    );
  }, []);
};

const OneSignalInit: FunctionComponent<Props> = ({ children }: Props) => {
  const { user } = useUser();
  const initializing = useRef(false);

  const login = useLoginOneSignal();
  const isNative = useIsNative();

  useEffect(() => {
    if (!user) return;
    if (isNative) return;

    if (initializing.current) return;
    initializing.current = true;

    (async () => {
      await initializeOneSignal();

      // FIXME
      // すでにログイン済みかどうかが分からないので、毎回ログインしてしまっている。
      // Permission変更後にログインする処理が不安定なので、次回初期化時にもログインするようにしている。
      await login();
    })();
  }, [user]);

  useLoginOneSignalOnChangePermission();

  return <>{children}</>;
};

const SentryInit = () => {
  const { user } = useUser();
  const { data: userData } = trpc.user.userData.useQuery(undefined, {
    enabled: !!user,
  });

  useEffect(() => {
    if (!userData) return;
    setSentryUser({
      id: String(userData.userId),
    });
  }, [userData]);

  return <></>;
};

const MyApp: AppType = ({ Component, pageProps }) => {
  const [needsRedirect, setNeedsRedirect] = useState<boolean>(false);

  useEffect(() => {
    const host = window.location.hostname;
    if (host === oldDomain) {
      setNeedsRedirect(true);
    }
  }, []);

  return needsRedirect ? (
    <DomainNotice />
  ) : (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,viewport-fit=cover,user-scalable=no"
        />
      </Head>
      <GoogleAnalytics trackPageViews />
      <AnimatePresence mode="wait">
        <UserProvider>
          <OneSignalInit>
            <Component {...pageProps} />
          </OneSignalInit>
          <SentryInit />
        </UserProvider>
      </AnimatePresence>
    </>
  );
};

export default trpc.withTRPC(MyApp);
