import { createContext, useContext } from "react";

import { trpc } from "@/utils/trpc";
import { LoadingView } from "./layout/loading";

type User = {
  userId: number;
  hasUserData: boolean;
};
type UserContextValue =
  | {
      user: User;
      isLogin: true;
      reload: ReturnType<typeof trpc.auth.getSession.useQuery>["refetch"];
    }
  | {
      user: null;
      isLogin: false;
      reload: ReturnType<typeof trpc.auth.getSession.useQuery>["refetch"];
    };

const UserContext = createContext({} as UserContextValue);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const { data: user, isLoading, refetch } = trpc.auth.getSession.useQuery();

  if (isLoading) {
    return <LoadingView />;
  }

  const value: UserContextValue = user
    ? { user, isLogin: true, reload: refetch }
    : { user: null, isLogin: false, reload: refetch };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUser() {
  return useContext(UserContext);
}
