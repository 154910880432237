import { useEffect, useState } from "react";

export const useIsNative = () => {
  const [isNative, setIsNative] = useState(false);
  useEffect(() => {
    const isNative = /nativeApp/i.test(navigator.userAgent);
    setIsNative(isNative);
  }, [setIsNative]);

  return isNative;
};
