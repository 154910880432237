import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { httpLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { compress, decompress } from "lz-string";

import type { AppRouter } from "@/server/routers/_app";

import { isQueryPersistenceEnabled } from "@/client/query_persistence_enabled";

function getBaseUrl() {
  if (typeof window !== "undefined")
    // browser should use relative path
    return "";

  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`;

  if (process.env.RENDER_INTERNAL_HOSTNAME)
    // reference for render.com
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ...
    },
  },
});

if (typeof window !== "undefined") {
  if (isQueryPersistenceEnabled()) {
    /*
     * localStorage にクエリ結果をキャッシュする
     * @link https://tanstack.com/query/v4/docs/framework/react/plugins/createSyncStoragePersister
     */
    persistQueryClient({
      queryClient: queryClient,
      persister: createSyncStoragePersister({
        storage: window.localStorage,
        serialize: (data) => compress(JSON.stringify(data)),
        deserialize: (data) => JSON.parse(decompress(data)),
      }),
      maxAge: 30 * 24 * 60 * 60, // 30 days
    });
  }
}

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      queryClient,
      links: [
        httpLink({
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           **/
          url: `${getBaseUrl()}/api/trpc`,
        }),
      ],
      /**
       * @link https://tanstack.com/query/v4/docs/reference/QueryClient
       **/
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
});
